import {Button, message, Modal, Space, Table} from "antd";
import {DeleteOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import Column from "antd/es/table/Column";
import {useEffect, useState} from "react";
import Api from "../../services/Api";
import SalesListPage from "./partials/SalesListPage";
import CardWriteComponent from "./partials/CardWriteComponent";

const DashboardPage = () => {
    const [recipe, setRecipe] = useState([])
    const [countText, setCountText] = useState("0")
    const [tempBuyList, setTempBuyList] = useState([]);
    const [orderConfirmModal, orderContextHolder] = Modal.useModal();
    const [refreshData, setRefreshData] = useState(false)
    const [cardWriteModal, setCardWriteModal] = useState({isOpened:false, writeData:""})

    useEffect(() => {
        getRecipe()
    }, []);

    const getRecipe = () => {
        Api.get("api/Recipes?PageIndex=0&PageSize=100").then(res => {
            setRecipe(res.data.items)
        }).catch(err => {
            message.error("Tarifeler getirilemedi").then(r => {})
        })
    }

    const numberClick = (number) => {
        if(countText.length > 2){
            message.error("En fazla 3 haneli olabilir")
            return;
        }
        //sıfır var ise sil ve yan yana ekle
        if(countText === "0"){
            setCountText(number)
        }

        //sıfır yok ise yanına ekle
        else{
            setCountText(countText +""+ number)
        }
    }

    const deleteNumber = () => {
        if(countText.length === 1){
            setCountText("0")
        }
        else{
            setCountText(countText.substring(0,countText.length-1))
        }
    }

    const addLine = (item) => {
        //eğer aynı id'den varsa adetini arttır
        let isExist = tempBuyList.find(x => x.recipeId === item.id)
        if(isExist){
            let index = tempBuyList.findIndex(x => x.recipeId === item.id)
            //99'dan fazla olamaz
            if(parseInt(tempBuyList[index].count) + parseInt(countText === "0" ? 1 : countText) > 99){
                message.error("Bir satırda en fazla 99 adet olabilir")
                return;
            }
            tempBuyList[index] = {
                recipeId: item.id,
                recipeCode: item.code,
                recipeName: item.name,
                recipePrice: item.price,
                count: parseInt(tempBuyList[index].count) + parseInt(countText === "0" ? 1 : countText),
                rowTotalPrice: (item.price * (parseInt(tempBuyList[index].count) + parseInt(countText === "0" ? 1 : countText)))
            }
            setTempBuyList([...tempBuyList])
            setCountText("0")
            return;
        }

        let newBuy = {
            recipeId: item.id,
            recipeName: item.name,
            recipePrice: item.price,
            recipeCode: item.code,
            count: countText === "0" ? 1 : countText,
            rowTotalPrice: (item.price * (countText==="0"? 1 : countText))
        }

        setTempBuyList([...tempBuyList, newBuy])
        setCountText("0")

    }

    const deleteLine = (id) => {
        let newBuyList = tempBuyList.filter(x => x.recipeId !== id)
        setTempBuyList(newBuyList)
    }

    const totalMoney = () => {
        let total = 0;
        tempBuyList.map((item) => {
            total += item.rowTotalPrice
        })
        return total;
    }

    const countAzalt = () => {
        if(countText === "0"){
            return;
        }
        setCountText(parseInt(countText) - 1)
    }

    const countArttir = () => {

        if(countText === "0")
            return;

        setCountText(parseInt(countText) + 1)
    }

    const buyListToWriteData = () => {
        //buyList R;CN01N00N0021013 bu şekilde veri elde edeceğiz!

        let writeData = "R;T"

        // group oluştur tarife ye göre
        const group = tempBuyList.reduce((r, a) => {
            r[a.recipeId] = [...r[a.recipeId] || [], a];
            return r;
        }, {});



        //group içerisindeki tarifeleri dön
        Object.keys(group).map((key) => {
            let recipe = group[key][0]
            let recipeCount = recipe.count
            let recipeCode = recipe.recipeCode

            //recipe count tek haneli ise başına 0 ekle değilse direk ekle iki kere yazıyor ???
            if(recipeCount < 10){
                recipeCount = "0"+recipeCount
            }
            writeData += recipeCode + recipeCount
        })

        //burada group sayısı 3 değilse
        if(Object.keys(group).length !== 3){
            //3'den var olanı çıkar ve o kadar N00 ekle
            let diff = 3 - Object.keys(group).length
            for(let i = 0; i < diff; i++){
                writeData += "N00"
            }
        }

        // sonra günü al ve ekle  //gün tek haneli ise başına 0 ekle
        //burada şuan ki günü al ve ekle
        let day = new Date().getDate();
        if(day < 10){
            writeData += "0"+day
        }
        else{
            writeData += day
        }


        // sonra ayı al ve ekle //ay tek haneli ise başına 0 ekle
        let month = new Date().getMonth()
        if(month < 10){
            writeData += "0"+month
        }
        else{
            writeData += month
        }

        //sonra yilin en son rakamını al ve ekle
        let year = new Date().getFullYear()
        writeData += year.toString().substring(3,4)

        // toplam karekter 17 olacak
        if(writeData.length !== 17){
            message.error("Veri oluşturulurken hata oluştu")
            return;
        }


        return writeData
    }

    const confirmOrder = () => {

        let _writeData = buyListToWriteData()
        setCardWriteModal({isOpened:true, writeData: _writeData})
    }

    const sendSales = () => {
        if(tempBuyList.length === 0){
            message.error("Satış yapılacak ürün bulunamadı").then(r => {})
            return;
        }

        const data = {
            buyer:"system",
            buyList:tempBuyList
        }

        Api.post("/api/buys", data).then(res => {
            message.success(res.data.message)
            setTempBuyList([])
            setRefreshData(!refreshData)
            setCardWriteModal({isOpened:false, writeData:""})

            // const total = tempBuyList.reduce((a, b) => a + parseInt(b.count), 0)
            // kapiAc(total)
        }).catch(err => {
            message.error("Satış işlemi gerçekleştirilemedi")
        })


    }



    return(
        <>
            <Modal
                title={"Kart Yükleme İşlemi"}
                open={cardWriteModal.isOpened}
                width={1000}
                maskClosable={false}
                closable={false}
                footer={[]}
                destroyOnClose={true}
                onCancel={() => setCardWriteModal({isOpened:false, writeData:""})}
            >
                <CardWriteComponent modalClose={() => sendSales()} writeData={cardWriteModal.writeData}></CardWriteComponent>
            </Modal>
            <div className={"buying-content"}>
                <div className={"buying-grid"}>
                    <div className={"buy-total-screen"}>
                        <div className={"text"}>Toplam Tutar</div>
                        <div className={"value"}>{totalMoney()?.toFixed(2)} TL</div>
                    </div>
                    <div className={"buying-list"}>
                        <div className={"table-card"}>
                            <Table rowKey={"id"}
                                   dataSource={tempBuyList}
                                   size={"small"}
                                   className={"h-100"} pagination={true}>
                                <Column title={""} width={40} dataIndex={""} render={(_, record) => {
                                    return(
                                        <Space>
                                            <Button onClick={() => deleteLine(record.recipeId)} size={"small"} icon={<DeleteOutlined />} ></Button>
                                        </Space>
                                    )
                                }}></Column>
                                <Column title={"Tarife"} className={"font-weight-bold"} dataIndex={"recipeName"}></Column>
                                <Column title={"Birim Fiyatı"} align={"right"} dataIndex={"recipePrice"}
                                        render={(_,record) => {
                                            return record?.recipePrice.toFixed(2) + " TL"
                                        }}>
                                </Column>
                                <Column title={`Adet`} align={"right"} dataIndex={"count"} render={(_, record) => {
                                    return record.count+" Kişi"
                                }}></Column>
                                <Column title={"Satır Toplam"} className={"font-weight-bold"} align={"right"} dataIndex={"rowTotalPrice"}
                                        render={(_,record) => {
                                            return record.rowTotalPrice.toFixed(2) + " TL"
                                        }}
                                ></Column>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className={"buying-order"}>
                    {orderContextHolder}
                    <div onClick={() => confirmOrder()} className={"order-success"}>
                        İŞLEMİ TAMAMLA
                    </div>
                    <div className={"number-label"}>
                        <input readOnly={true} value={countText}></input>
                    </div>
                    <div className={"number-section"}>
                        <div className={"buttons"}>
                            <div onClick={() => numberClick(1)} className={"number-button"}>1</div>
                            <div onClick={() => numberClick(2)} className={"number-button"}>2</div>
                            <div onClick={() => numberClick(3)} className={"number-button"}>3</div>
                            <div onClick={() => numberClick(4)} className={"number-button"}>4</div>
                            <div onClick={() => numberClick(5)} className={"number-button"}>5</div>
                            <div onClick={() => numberClick(6)} className={"number-button"}>6</div>
                            <div onClick={() => numberClick(7)} className={"number-button"}>7</div>
                            <div onClick={() => numberClick(8)} className={"number-button"}>8</div>
                            <div onClick={() => numberClick(9)} className={"number-button"}>9</div>
                            <div onClick={() => numberClick(0)} className={"number-button"}>0</div>
                            <div className={"number-button"} onClick={() => setCountText("0")} style={{fontSize:10}}>TEMİZLE</div>
                        </div>
                        <div className={"right-buttons"}>
                            <div onClick={() => deleteNumber()} style={{fontSize:22}} className={"action-button"}>DÜZELT</div>
                            <div onClick={() => countAzalt()} className={"action-button"}>-</div>
                            <div onClick={() => countArttir()} className={"action-button"}>+</div>
                            <div className={"action-button"} style={{fontSize:21}}>SATIR SİL</div>
                        </div>
                    </div>
                </div>
                <div className={"buying-options"}>
                    {recipe.map((item, index) => {
                        return(
                            <div onClick={() => addLine(item)} key={index} className={"item"}>
                                <div className={"text"}>{item.name}</div>
                                <div className={"value"}>{item.price} TL</div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={"sell-list"}>
               <SalesListPage refreshData={refreshData}></SalesListPage>
            </div>
        </>
    )
}

export default DashboardPage