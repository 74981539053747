import {Button, Card, Col, Form, Input, InputNumber, message, Row, Space, Switch} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import Api from "../../services/Api";
import {useLocation, useNavigate} from "react-router-dom";

const RecipeDetail = () => {
    const [loading, setLoading] = useState(true)
    const [form] = Form.useForm();
    const { state } = useLocation();
    const navigate = useNavigate()

    const getData = () => {
        Api.get("/api/recipes/" + state.id).then(res => {
            setTimeout(function () {
                form.setFieldsValue(res.data);
                setLoading(false)
            }, 0);
        }).catch(err => message.error("Tarife getirilemedi..")).finally(() => setLoading(false));
    }

    const onFinish = (values) => {
        Api.put("/api/recipes", values).then(res => {
            navigate("/recipe")
            message.success("Güncelleme işlemi başarılı bir şekilde gerçekleşti")
        }).catch(err => {
            message.error("Güncelleme işlemi başarısız oldu...")
        })
    }

    const onFinisFailed = () => {
        message.error("Lütfen tüm alanları doldurunuz")
    }

    useEffect(() => {
        getData()
    }, []);

    return(<div className={"p-1"}>
        <Card title={"Tarife Düzenle"} loading={loading}  extra={<Button size={"small"} onClick={() => navigate("/tarife")}><ArrowLeftOutlined /></Button>}>
            <Form
                form={form}
                labelAlign={"left"}
                initialValues={{ status: true }}
                onFinish={onFinish}
                onFinishFailed={onFinisFailed}
                layout={"horizontal"}
                labelCol={{
                    span: 15,
                }}
            >
                <Row gutter={[10, 0]}>
                    <Col span={12}>

                        <Col span={24}>
                            <Form.Item hidden={true} label={"Id"} name={"id"} rules={[{ required: true },]}>
                                <Input></Input>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label={"Tarife Durumu"} valuePropName={"checked"} name="status" required={true}>
                                <Switch defaultChecked={true} checkedChildren="Tarife Yayınlansın" unCheckedChildren="Tarife Yayınlanmasın" />
                            </Form.Item>
                        </Col>


                        <Col span={24}>
                            <Form.Item label={"Tarife Kodu"} name={"code"} rules={[{required: true},]}>
                                <Input></Input>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label={"Tarife Adı"} name={"name"} rules={[{required: true},]}>
                                <Input></Input>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label={"Tarife Tutar"} name={"price"}
                                       rules={[{required: true},]}>
                                <InputNumber step={0.25} min={1} stringMode  formatter={""} addonAfter={"TL"} className={"w-100"}></InputNumber>
                            </Form.Item>
                        </Col>

                        <Col span={24} className={"text-align-right"}>
                            <Space>
                                <Button type={"primary"} htmlType="submit">Kaydet</Button>
                                <Button type={"default"} onClick={() => navigate("/users")}>Geri Dön</Button>
                            </Space>
                        </Col>
                    </Col>
                </Row>
            </Form>
        </Card>
    </div>)
}

export default RecipeDetail