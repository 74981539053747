import dayjs from "dayjs";
import Api from "../../../services/Api";
import {message, Table} from "antd";
import {useEffect, useState} from "react";
import Column from "antd/es/table/Column";

const SalesListPage  = ({refreshData}) => {
    const [data, setData] = useState([])

    useEffect(() => {
        getData()
    }, [refreshData]);

    const getData = () => {
        const query = {
            "sort": [
                {
                    "field": "createdDate",
                    "dir": "desc"
                }
            ],
            "filter": {
                "field": "CreatedDate.Date",
                "operator": "eq",
                "value": `${dayjs(new Date()).format("YYYY-MM-DD")}`,
                "logic": "and"
            }
        }


        Api.post("/api/Buys/GetList/ByDynamic?PageIndex=0&PageSize=10000", query).then(res => {
            res.data.items.map((item) => {
                let total = 0;
                let detail= "";
                item.buyDetails.map((buy) => {
                    total += buy.recipeRowTotalPrice
                    detail += buy.recipeName + " " + buy.count + " Kişi, "
                })
                item.priceTotal = parseInt(total.toFixed(2))
                item.detail = detail
            })

            setData(res.data.items)

        }).catch(err => {
            console.log(err)
            message.error("Gerçekleşen satışlar getirilemedi")
        })
    }

    return(
        <Table
            rowKey={"id"}
            dataSource={data}
            size={"small"}
            pagination={false}
        >

            <Column width={175} title={"Zaman"} dataIndex={"createdDate"} render={(_,record) => {
                return dayjs(record.createdDate).format("DD.MM.YYYY HH:ss")
            }}></Column>
            <Column width={120} title={"Satış Tipi"}  align={"center"} dataIndex={"buyType"}></Column>
            <Column width={200} title={"İşlem Yapan"} align={"center"} dataIndex={"buyer"}></Column>
            <Column width={200} render={priceTotal => (priceTotal.toFixed(2) + " TL")} title={`Toplam Tutar (${(data.reduce((total, item) => total + item.priceTotal, 0)).toFixed(2)} TL)`} align={"right"} dataIndex={"priceTotal"}></Column>
            <Column title={"Satış Detay"} align={"left"} dataIndex={"detail"}></Column>

        </Table>
    )
}

export default SalesListPage