import {useState} from "react";
import {Button, Card, Col, Form, Input, InputNumber, message, Modal, Row, Space, Switch} from "antd";
import {useNavigate} from "react-router-dom";
import {ArrowLeftOutlined} from "@ant-design/icons";
import Api from "../../services/Api";

const RecipeAddPage = () => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const navigate = useNavigate()

    const onFinisFailed = () => {
        message.error("Bir şeyler ters gitti. Lütfen tekrar deneyin.")
    }

    const onFinish = (values) => {
        Api.post("/api/Recipes", values).then(res => {
            navigate("/recipe")
            message.success("Tarife başarıyla eklendi")
        }).catch(err => {
            message.error("Tarife eklenirken bir hata oluştu")
        })
    }

    return(<div className={"p-2"}>
        <Card title={"Tarife Ekle"} loading={loading}
              extra={<Button size={"small"} onClick={() => navigate("/recipe")}><ArrowLeftOutlined/></Button>}>

            <Form
                form={form}
                labelAlign={"left"}
                initialValues={{status: true}}
                onFinish={onFinish}
                onFinishFailed={onFinisFailed}
                layout={"horizontal"}
                labelCol={{
                    span: 5,
                }}
                colon={false}
            >
                <Row gutter={[10, 0]}>
                    <Col span={12}>

                        <Col span={24}>
                            <Form.Item label={"Tarife Durumu"} valuePropName={"checked"} name="status"
                                       required={true}>
                                <Switch defaultChecked={true} checkedChildren="Tarife Yayınlansın"
                                        unCheckedChildren="Tarife Yayınlanmasın"/>
                            </Form.Item>
                        </Col>


                        <Col span={24}>
                            <Form.Item label={"Tarife Kodu"} name={"code"} rules={[{required: true},]}>
                                <Input></Input>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label={"Tarife Adı"} name={"name"} rules={[{required: true},]}>
                                <Input></Input>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label={"Tarife Tutar"} name={"price"}
                                       rules={[{required: true},]}>
                                <InputNumber step={0.25} min={1} stringMode formatter={""} addonAfter={"TL"}
                                             className={"w-100"}></InputNumber>
                            </Form.Item>
                        </Col>

                        <Col span={24} className={"text-align-right"}>
                            <Space>
                                <Button type={"primary"} htmlType="submit">Kaydet</Button>
                                <Button type={"default"} onClick={() => navigate("/users")}>Geri Dön</Button>
                            </Space>
                        </Col>
                    </Col>
                </Row>
            </Form>



        </Card>
    </div>)
}

export default RecipeAddPage